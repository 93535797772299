import React from 'react'
import { Helmet } from 'react-helmet';
import '../css/class-by-age.css';

export default function ClassbyAge() {
    return (
        <div className="classByAge-content">
            <Helmet>
                <title>In Motion Dance | Classes By Age</title>
            </Helmet>
            <h1>Classes by Age - Fall 2025</h1>
            <div style={{textAlign: 'center'}}>
                <h4>Updated: Friday, March 7, 2025</h4>
            </div>
            <h4><br/></h4>
            {/* These are the 18 month to 3 year old classes */}
            <div className="classAge-18mon-3yrs">
                <table>
                    <tr>
                        <th>AGES 18 mon - 3 years OR 2-5 yrs</th>
                    </tr>
                    <tr>
                        <td>Tumbling Tots (AGES 2-5)</td>
                        <td>Tuesday</td>
                        <td>3:30-4:15</td>
                        <td>Destanee</td>
                    </tr>
                    <tr>
                        <td>MCT (Mini-combo Dance/Tumbling)</td>
                        <td>Wednesday</td>
                        <td>10:15-10:45</td>
                        <td>Lee</td>
                    </tr>
                </table>
            </div>


            {/* This are the 3 to 5 year old classes */}
            <div className="classAge-3-5yrs">
                <table>
                    <tr>
                        <th>AGES 3-5 or AGES 3-6 years</th>
                    </tr>
                    <tr>
                        <td>BTT (Ballet/Tap/Tumbling)<strong> (FULL)</strong></td>
                        <td>Monday</td>
                        <td>5:30-6:15</td>
                        <td>Karena</td>
                    </tr>
                    <tr>
                        <td>BJT (Ballet/Jazz/Tumbling)<strong> (FULL)</strong></td>
                        <td>Tuesday</td>
                        <td>9:45-10:30</td>
                        <td>Destanee</td>
                    </tr>
                    <tr>
                        <td>BTT (Ballet/Tap/Tumbling)</td>
                        <td>Tuesday</td>
                        <td>10:30-11:15</td>
                        <td>Destanee</td>
                    </tr>
                    <tr>
                        <td>BTT (Ballet/Tap/Tumbling)</td>
                        <td>Wednesday</td>
                        <td>10:45-11:30</td>
                        <td>Lee</td>
                    </tr>
                    <tr>
                        <td>BTT (Ballet/Tap/Tumbling)</td>
                        <td>Thursday</td>
                        <td>4:00-4:45</td>
                        <td>Lee</td>
                    </tr>
                    <tr>
                        <td>BTT+ (Ballet/Tumbling/Bonus Styles)</td>
                        <td>Friday</td>
                        <td>10:30-11:30</td>
                        <td>Lee</td>
                    </tr>
                    <tr>
                        <td>PreSchool (AGES 3-4)</td>
                        <td>Tuesday & Thursday</td>
                        <td>9:30-11:30</td>
                        <td>Lee</td>
                    </tr>
                    
                    <tr>
                        <td>PreSchool (AGES 4-5)</td>
                        <td>T/W/TH/F</td>
                        <td>12:30-2:30</td>
                        <td>Lee</td>
                    </tr>
                </table>
            </div>


            {/* This are the 5 to 7 year old classes */}
            <div className="classAge-5-7yrs">
                <table>
                    <tr>
                        <th>AGES 5-7 years</th>
                    </tr>
                    <tr>
                        <td>Hip Hop/Jazz 1<br/></td>
                        <td>Monday</td>
                        <td>3:50-4:40</td>
                        <td>Briley</td>
                    </tr>
                    <tr>
                        <td>Int BTT <br/></td>
                        <td>Monday</td>
                        <td>4:45-5:30</td>
                        <td>Adelaide</td>
                    </tr>
                    <tr>
                        <td>Tumbling 1</td>
                        <td>Tuesday</td>
                        <td>4:15-5:15</td>
                        <td>Destanee</td>
                    </tr>
                    <tr>
                        <td>Ballet 1</td>
                        <td>Wednesday</td>
                        <td>4:00-4:45</td>
                        <td>Lee</td>
                    </tr><tr>
                        <td>Ballet 1<br/></td>
                        <td>Wednesday</td>
                        <td>4:45-5:30</td>
                        <td>Lee</td>
                    </tr>
                    

                </table>
            </div>


            {/* This are the 8 to 11 year old classes */}
            <div className="classAge-8-11yrs">
                <table>
                    <tr>
                        <th>AGES 8-11 years</th>
                    </tr>
                    <tr>
                        <td>Tumbling 2</td>
                        <td>Monday</td>
                        <td>5:45-6:45</td>
                        <td>Adelaide</td>
                    </tr>
                    <tr>
                        <td>Tap 2</td>
                        <td>Monday</td>
                        <td>6:45-7:30</td>
                        <td>Karena</td>
                    </tr>
                    <tr>
                        <td>Contemporary/Lyrical 2/3 (AGES 8+)<br/></td>
                        <td>Tuesday</td>
                        <td>6:45-7:45</td>
                        <td>Jaclyn</td>
                    </tr>
                    <tr>
                        <td>Ballet 2</td>
                        <td>Wednesday</td>
                        <td>5:30-6:30</td>
                        <td>Riley</td>
                    </tr>
                    <tr>
                        <td>Hip Hop 2</td>
                        <td>Wednesday</td>
                        <td>6:00-7:00</td>
                        <td>Lee</td>
                    </tr>
                    <tr>
                        <td>Jazz 2</td>
                        <td>Wednesday</td>
                        <td>6:30-7:30</td>
                        <td>Riley</td>
                    </tr>
                    <tr>
                        <td>Emerge Dance Company<br/><b>(Tryout Req)</b></td>
                        <td>Friday</td>
                        <td>4:00-5:30</td>
                        <td>Destanee</td>
                    </tr>
                </table>
            </div>


            {/* This are the 12 to 17 year old classes */}
            <div className="classAge-12-17yrs">
                <table>
                    <tr>
                        <th>AGES 12+ years</th>
                    </tr>
                    <tr>
                        <td>Ballet 5/6 (Pre-Pointe)<br/><strong>(Approval Required)</strong></td>
                        <td>Monday</td>
                        <td>6:15-7:00</td>
                        <td>Sadie</td>
                    </tr>
                    <tr>
                        <td>Eclipse Dance Company<br/><b>(Tryout Req)</b></td>
                        <td>Monday<br/>Wednesday</td>
                        <td>7:00-8:00<br/>5:00-6:00</td>
                        <td>Destanee</td>
                    </tr>
                    <tr>
                        <td>Teen & Adult Tap</td>
                        <td>Monday</td>
                        <td>7:30-8:30</td>
                        <td>Karena</td>
                    </tr>
                    <tr>
                        <td>Pointe <br/><strong>(2 Classes Required)</strong></td>
                        <td>Tuesday</td>
                        <td>4:00-5:00</td>
                        <td>Lee</td>
                    </tr>
                    <tr>
                        <td>Tumbling 3<br/><strong>(Skill Level)</strong></td>
                        <td>Tuesday</td>
                        <td>5:15-6:45</td>
                        <td>Destanee</td>
                    </tr>
                    <tr>
                        <td>Elite Dance Company<br/><b>(Tryout Req)</b></td>
                        <td>Wednesday<br/>Friday</td>
                        <td>3:30-5:00<br/>3:30-5:00</td>
                        <td>Wendy</td>
                    </tr>
                    <tr>
                        <td>Pointe <br/><strong>(2 Classes Required)</strong></td>
                        <td>Wednesday</td>
                        <td>7:00-8:20</td>
                        <td>Wendy</td>
                    </tr>
                    <tr>
                        <td>Pointe<br/><strong>(2 Classes Required)</strong></td>
                        <td>Thursday</td>
                        <td>5:00-6:00</td>
                        <td>Lee</td>
                    </tr>
                    <tr>
                        <td>Tap 3<br/><strong>(Approval Required)</strong></td>
                        <td>Thursday</td>
                        <td>5:00-6:00</td>
                        <td>Karena</td>
                    </tr>
                    <tr>
                        <td>Ballet 5/6<br/><strong>(Skill Level)</strong></td>
                        <td>Thursday</td>
                        <td>6:00-7:00</td>
                        <td>Lee</td>
                    </tr>
                    <tr>
                        <td>Jazz 3/4</td>
                        <td>Thursday</td>
                        <td>6:00-7:00</td>
                        <td>Sadie</td>
                    </tr>
                    <tr>
                        <td>Ballet 3/4<br/><strong>(Approval Required)</strong></td>
                        <td>Thursday</td>
                        <td>7:00-8:00</td>
                        <td>Vivian</td>
                    </tr>
                    <tr>
                        <td>Hip Hop 3/4</td>
                        <td>Thursday</td>
                        <td>7:00-8:00</td>
                        <td>Destanee</td>
                    </tr>
                    <tr>
                        <td>Break 3<br/><strong>(Approval Required)</strong></td>
                        <td>Saturday</td>
                        <td>11:00-12:00</td>
                        <td>Nate</td>
                    </tr>
                </table>
            </div>

            {/* These are the ADULT classes */}
            <div className="classAge-Adult">
                <table>
                    <tr>
                        <th>ADULT</th>
                    </tr>
                    <tr>
                        <td>Adult Tap</td>
                        <td>Monday</td>
                        <td>7:30-8:30</td>
                        <td>Karena</td>
                    </tr>
                    <tr>
                        <td>Adult Ballet</td>
                        <td>Tuesday</td>
                        <td>5:00-6:00</td>
                        <td>Lee</td>
                    </tr>
                    <tr>
                        <td>Adult Hip Hop</td>
                        <td>Tuesday</td>
                        <td>6:00-6:45</td>
                        <td>Lee</td>
                    </tr>
                </table>
            </div>                                        
        </div>
    )
}